<template id="optasetup">
  <b-container fluid>
    <h3>Opta Team Setup</h3>
    <b-row>
      <b-col sm="6" md="6" lg="4">
        <h5>Skills</h5>
        <b-row no-gutters>
          <b-col cols="1"> # </b-col>
          <b-col cols="3"> Name </b-col>
          <!--b-col cols="2">
            Abbr
          </b-col-->
          <b-col> Label </b-col>
          <b-col cols="1" title="standard"> Std </b-col>
          <!--b-col cols="1">
            %
          </b-col-->
          <b-col cols="1" title="reverse"> Rev </b-col>
          <b-col cols="1" title="average"> Avg </b-col>
          <!--b-col cols="1">
            Act
          </b-col-->
        </b-row>
        <div style="max-height: 73vh; overflow-y: scroll">
          <draggable
            v-model="skills"
            :group="{ name: 'skills', pull: 'clone', put: false }"
          >
            <div v-for="skill in skills" :key="skill.id">
              <b-card
                no-body
                class="mb-1"
                :style="
                  'cursor:move;' +
                  (skill.phase != null
                    ? 'background: rgba(173, 255, 47, .5)'
                    : '')
                "
              >
                <b-row no-gutters align-v="center">
                  <b-col cols="1">
                    {{ skill.order }}
                  </b-col>
                  <b-col cols="3">
                    {{ skill.name }}
                  </b-col>
                  <!--b-col cols="2">
                    <b-form-input v-model="skill.sigla"></b-form-input>
                  </b-col-->
                  <b-col>
                    <b-form-input
                      v-model="skill.label"
                      placeholder="Enter label for skill"
                      :title="skill.label"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.standard"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <!--b-col cols="1">
                    <b-form-checkbox
                      disabled
                      v-model="skill.percent"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col-->
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.reverse"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="skill.average"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <!--b-col cols="1">
            <b-form-checkbox
            v-model="skill.active"
            :value="1"
            :unchecked-value="0"
            >
          </b-form-checkbox>
        </b-col-->
                </b-row>
              </b-card>
            </div>
          </draggable>
        </div>
        <b-button size="sm" variant="primary" class="mt-2" @click="updateSkills"
          >Update skills</b-button
        >
        <b-button
          size="sm"
          variant="secondary"
          class="mt-2 ml-2"
          @click="pdfPhases"
          >Print phases</b-button
        >
      </b-col>
      <b-col>
        <h5>Phases</h5>
        <div style="max-height: 75vh; overflow-y: scroll">
          <b-row no-gutters id="phases_content" ref="phases_content">
            <b-col
              sm="12"
              md="12"
              lg="6"
              v-for="(fase, index) in phases"
              :key="'phase_' + index"
              class="p-1"
            >
              <b-card :header="fase" no-body>
                <b-card-body class="py-1">
                  <b-row>
                    <b-col>
                      <draggable
                        :list="getSkillsByPhase(index + 1)"
                        :group="{ name: 'skills' }"
                        @change="addSkillPhase($event, index + 1)"
                        style="min-height: 50px; margin-bottom: 10px"
                      >
                        <div
                          v-for="item in getSkillsByPhase(index + 1)"
                          :key="'phase_' + (index + 1) + '_' + item.id"
                        >
                          <b-badge
                            variant="primary"
                            :title="item.label"
                            style="width: 380px"
                            class="py-1"
                          >
                            <b-row no-gutters>
                              <b-col>
                                <div
                                  style="
                                    cursor: move;
                                    font-size: 0.8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 340px;
                                    text-align: left;
                                  "
                                >
                                  {{ item.name }} -
                                  {{ item.label }}
                                </div>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  icon="x"
                                  scale="2"
                                  style="cursor: pointer"
                                  @click="removeSkillPhase(item)"
                                ></b-icon>
                              </b-col>
                            </b-row>
                          </b-badge>
                        </div>
                      </draggable>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import draggable from "vuedraggable";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: function () {
    return {
      phases: [],
      skills: [],
    };
  },

  components: {
    draggable,
  },

  created: function () {
    this.getSkills();
    this.getPhases();
  },

  methods: {
    getSkills() {
      this.$http.get("/opta/team/skills").then((response) => {
        this.skills = response.data;
      });
    },
    getPhases() {
      this.$http.get("/opta/team/phases").then((response) => {
        this.phases = response.data;
      });
    },
    getSkillsByPhase(phase) {
      return this.skills.filter((elem) => elem.phase === phase);
    },
    removeSkillPhase: function (skill) {
      skill.phase = null;
    },
    addSkillPhase: function (evt, phase) {
      //  console.log(evt);
      if (evt.added) {
        evt.added.element.phase = phase;
      }
    },
    updateSkills() {
      this.$http
        .post("/opta/update/team/skills", this.skills)
        .then((response) => {
          this.getSkills();
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
        });
    },
    pdfPhases() {
      var canvasElement = document.createElement("canvas");
      html2canvas(this.$refs.phases_content, { canvas: canvasElement }).then(
        function (canvas) {
          var imgData = canvas.toDataURL("image/png");
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF("p", "mm");
          var position = 0;

          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save("team_phases_skills.pdf");
        }
      );
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("MM/DD/YYYY");
      }
      return "";
    },
  },
};
</script>
